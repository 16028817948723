/** @jsx jsx */
import { jsx, useColorMode } from "theme-ui"
import Button from "./button"


const modes = ["light", "colorful", "rose"]
export default props => {
  const [mode, setMode] = useColorMode()
  return (
    <Button
      sx={{
        variant: "buttons.simple",
        m: 1,
        p: 1,
        width: '50px',
      }}
      {...props}
      onClick={e => {
        const index = modes.indexOf(mode)
        const next = modes[(index + 1) % modes.length]
        setMode(next)
      }}
    />
  )
}
