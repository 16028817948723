/** @jsx jsx */
// eslint-disable-next-line
import React from "react"
// eslint-disable-next-line
import { jsx } from "theme-ui"
import Typography from "typography"
//import * as fonts from "../fonts"

/* const LARGER_DISPLAY_WIDTH = "1600px"
const LARGE_DISPLAY_WIDTH = "1280px"
const DEFAULT_WIDTH = "980px"
const TABLET_WIDTH = "768px"
const MOBILE_WIDTH = "480px"

const LARGER_DISPLAY_MEDIA_QUERY =
  "@media only screen and (max-width:1600px)"
const LARGE_DISPLAY_MEDIA_QUERY =
  "@media only screen and (max-width:1280px)"
const DEFAULT_MEDIA_QUERY = "@media only screen and (max-width:980px)" */
const TABLET_MEDIA_QUERY = "@media only screen and (max-width:768px)"
const MOBILE_MEDIA_QUERY = "@media only screen and (max-width:480px)"

/* const MIN_LARGER_DISPLAY_MEDIA_QUERY = "@media (min-width:1600px)"
const MIN_LARGE_DISPLAY_MEDIA_QUERY = "@media (min-width:1280px)"
const MIN_DEFAULT_MEDIA_QUERY = "@media (min-width:980px)"
const MIN_TABLET_MEDIA_QUERY = "@media (min-width:768px)"
const MIN_MOBILE_MEDIA_QUERY = "@media (min-width:480px)" */


const typography = new Typography({
  baseFontSize: "20px",
  googleFonts: [
    {
      name: "Open sans",
      styles: ["300", "300i", "700", "700i"],
    },
    {
      name: "Rubik",
      styles: ["300", "400", "400i", "700", "700i"],
    },
  ],
  headerFontFamily: ["Rubik", "display"],
  bodyFontFamily: ["Open sans", "sans-serif"],
  bodyColor: "text",
  headerWeight: 700,
  bodyWeight: 300,
  boldWeight: 700,
  overrideStyles: ({ adjustFontSizeTo, scale, rhythm }, options) => ({
    a: {
      textDecoration: "none",
    },
    "a:hover,a:active": {
      textDecoration: "underline",
    },
    [MOBILE_MEDIA_QUERY]: {
      "ul,ol": {
        marginLeft: rhythm(1),
      },
      blockquote: {
        marginLeft: rhythm(-3 / 4),
        marginRight: 0,
        paddingLeft: rhythm(9 / 16),
      },
    },
    [TABLET_MEDIA_QUERY]: {
      h1: {
        ...scale(6 / 5),
      },
    },
    [MOBILE_MEDIA_QUERY]: {
      h1: {
        ...scale(5 / 5),
      },
    },
    h1: {
      ...scale(10 / 5),
      letterSpacing: "-2px",
    },
    h6: {
      fontStyle: "italic",
    },
    logo: {
      fontFamily: "KiriFont",
      fontStyle: "normal",
      fontWeight: "normal",
      src:
        "url('../fonts/kirifont-webfont.woff2') format('woff2'), url('../fonts/kirifont-webfont.woff') format('woff'), url('../fonts/KiriFont.otf') format(opentype)",
    },
  }),
})

export default typography
