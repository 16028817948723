/** @jsx jsx */
import { jsx } from "theme-ui";
// eslint-disable-next-line
import React from "react";

const Logo = () => (
  <logo
    sx={{
      fontSize: 4,
      lineHeight: 1.78,
      letterSpacing: "-1.5px",
      m: 0,
      p: 0,
    }}
  >
    AllTails
  </logo>
)

export default Logo