/** @jsx jsx */
// eslint-disable-next-line
import React from "react"
import { jsx, ThemeProvider } from "theme-ui"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import Header from "./header"
import Footer from "./footer"
import theme from "../gatsby-plugin-theme-ui/index.js"
import { TypographyStyle, GoogleFont } from "react-typography"
// Best practice is to have a typography module
// where you define your theme.
import typography from "utils/typography"
import { library } from "@fortawesome/fontawesome-svg-core"
import { fab } from "@fortawesome/free-brands-svg-icons"
import {
  faDog,
  faBrain,
  faHeartbeat,
  faPaw,
  faHeart,
  faExpand,
  faShare,
  faBone,
  faHeartRate,
  faLightbulbOn,
  faFilter,
  faSearch,
  faSearchPlus,
  faSignIn,
  faUserPlus,
  faPalette,
  faUser,
  faSun,
} from "@fortawesome/pro-solid-svg-icons"

library.add(
  fab,
  faDog,
  faBrain,
  faHeartbeat,
  faPaw,
  faHeart,
  faExpand,
  faShare,
  faBone,
  faHeartRate,
  faLightbulbOn,
  faFilter,
  faSearch,
  faSearchPlus,
  faSignIn,
  faUserPlus,
  faPalette,
  faUser,
  faSun,
  )

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <ThemeProvider theme={{ mode: "light" }}>
        <TypographyStyle typography={typography} />
        <GoogleFont typography={typography} />
        <div
          sx={{
            display: "flex",
            flexDirection: "column",
            minHeight: "100vh",
            variant: "layout.root",
          }}
        >
          <Header siteTitle={data.site.siteMetadata.title} />

          <main
            sx={{
              variant: "layout.main",
            }}
          >
            {children}
          </main>

          <Footer />
        </div>
      </ThemeProvider>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
