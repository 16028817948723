
/** @jsx jsx */
import { jsx, ThemeProvider } from "theme-ui"
import { Link } from "gatsby"


       /*   a: {
           color: "primary",
           ":hover, :focus": {
             color: "secondary",
           }, */

const styledLink = ({ variant = "primary", ...props }) => (
  <Link
    {...props}
    sx={{
      color: "primary",
      "&:hover": {
        color: "primaryDark",
      },
      "&:focus": {
        color: "secondary",
      },
    }}
  />
)
export default styledLink