/** @jsx jsx */
import { jsx } from "theme-ui"
// eslint-disable-next-line
import React from "react"
import styled from "styled-components"
import Link from "./link"
import { Box, Flex } from "rebass"
import { Input } from "@rebass/forms"
import PropTypes from "prop-types"
import Logo from "./logo.js"
import Button from "./button"
import { Container } from "./layoutComponents"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import ModeToggle from "./modeToggle"

const Header = ({ siteTitle, className }) => (
  <header
    sx={{
      width: "100vw",
      p: 0,
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      zIndex: 9999,
      bg: "background",
    }}
  >
    <Container>
      <Flex alignItems="center">
        <Link
          to="./"
          sx={{
            my: 2,
            color: "text",
          }}
        >
          <Logo
            sx={{
              m: 2,
              p: 1,
            }}
          />
        </Link>
        <Flex
          sx={{
            p: 3,
            width: "100%",
          }}
        >
          <Input
            sx={{
              p: 2,
              m: 1,
              variant: "buttons.gray",
              width: 128,
              borderRadius: 4,
              fontSize: 2,
              transition: "width 0.4s ease-in-out",
              "&:hover": {
                bg: "rgba(48, 53, 54, 0.05)",
                borderColor: "transparent",
                color: "primaryDark",
              },
              "&:focus": {
                width: "100%",
              },
            }}
            id="location"
            name="location"
            defaultValue="98101"
          />
          <Button
            sx={{
              p: 2,
              m: 1,
              variant: "buttons.gray",
              width: 43.2,
              borderRadius: 4,
              fontSize: 2,
              "&:hover": {
                bg: "primary",
                borderColor: "primary",
              },
            }}
          >
            <FontAwesomeIcon icon={["fas", "search"]} />
          </Button>
        </Flex>
        <Box mx="auto" />

        <Link
          to="./signup/"
          sx={{
            m: 1,
            p: 2,
            whiteSpace: "nowrap",
            fontSize: 2,
          }}
        >
          Early Access Page
        </Link>
        <ModeToggle>
          <FontAwesomeIcon icon={["fas", "sun"]} />
        </ModeToggle>
      </Flex>
    </Container>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

const StyledHeader = styled(Header)``

export default StyledHeader
