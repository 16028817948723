/** @jsx jsx */
// eslint-disable-next-line
import React from "react";
import { jsx } from "theme-ui";
import Link from "./link"
import styled from "styled-components";
import { Container } from "./layoutComponents";

const Footer = ({ className }) => (
  <footer
    sx={{
      variant: "layout.footer",
      m: 4,
    }}
  >
    <Container>
      © {new Date().getFullYear()}, Built with ❤️ by
      {` `}
      <Link to="./">
        <span
          sx={{
            fontFamily: "KiriFont",
            letterSpacing: "-1.5px",
          }}
        >
          {" "}
          AllTails{" "}
        </span>
      </Link>
    </Container>
  </footer>
)

const StyledFooter = styled(Footer)``;

export default StyledFooter;
