/** @jsx jsx */
import { jsx, ThemeProvider } from "theme-ui"

const Button = ({ variant = "primary", ...props }) => (
    <button
      {...props}
      sx={{
        appearance: "button",
        textTransform: "uppercase",
        display: "inline-block",
        textAlign: "center",
        lineHeight: "inherit",
        textDecoration: "none",
        fontSize: "inherit",
        fontWeight: "bold",
        width: "100%",
        m: 0,
        p: 2,
        borderRadius: 4,
        //pass variant prop to sx
        variant: `buttons.${variant}`,
      }}
    />
)
export default Button
